import React, { useEffect, useRef, useState } from "react";
import Table from "antd/lib/table";
import {
  convertToLocalTime,
  getTheTimeDifference,
  openNotificationWithIcon,
} from "../../utils";
import { CloseOutlined, EyeOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { PdfViewer } from "../pdf-viewer/PdfViewer";
import "./documents.scss";
import { useNavigate } from "react-router-dom";
import { AppPagination } from "../app-pagination/AppPagination";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import { documentService } from "../../api";
import { Input } from "antd/lib";
import { SearchIcon } from "../../assets/icons";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Empty,
  Row,
  Select,
  Spin,
  Statistic,
  Tag,
} from "antd";
import Search from "antd/lib/input/Search";
import { Loading, DatePicker } from "../../components";
import "../../assets/sass/layout/search.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setSelectedDateRange } from "../../store";
import moment from "moment";
import { render } from "react-dom";
import DrawerComponent from "../drawer/Drawer";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {
  projectId?: number;
  loading?: boolean;
}

interface IUploadedDocumentsDataType {
  key: React.Key;
  name: string;
  updatedOn: string;
  documentUUID: string;
  noOfPages: number;
  noOfPlaceholders: number;
  status: string;
  noOfReviews: number;
  createdOn: string;
  acknowledgement?: string;
}

const initialSelectRows = {
  displayCheckBox: false,
  exporting: false,
  rows: [] as string[],
};

const statusColors: any = {
  READY: "green",
  PROCESSING: "gold",
  FAILED: "red",
  unknown: "gray",
};



export const Documents = (props: Props) => {
  const { projectId } = props;
  const navigate = useNavigate();
  const {
    fetchDocuments,
    documents,
    isDocumentsLoading,
    paginationObj,
    totalDocuments,
    searchTerm,
    setSearchTerm,
    status,
    setStatus,
    fromDate,
    toDate,
    isDocAckLoading,
    fetchDocAckData,
    docAckData,
    sortFilter,
    fetchRetryData,
    retryData,
    onSortChange
  } = useFetchDocuments();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;

  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const [selectRows, setSelectRows] = useState(initialSelectRows);
  const [searchInput, setSearchInput] = useState("");
  const [drawer, setDrawer] = useState(false);
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | any>(null);
  const [activeDocData, setActiveDocData] = useState({
    fileName: "",
    uuid: "",
    ackStatus: ""
  });
  const [ retryingDoc, setRetryingDoc ] = useState<string>('');

  const handleDrawer = async (fileName: string = "", uuid: string = "", ackStatus: string = "") => {
    const docData = { fileName, uuid, ackStatus };
    setActiveDocData(docData);
    setDrawer(!drawer);
    if(uuid.length){
      await fetchDocAckData(uuid);
      await fetchRetryData(uuid);
    }
  };

  const tableStyle: React.CSSProperties = {
    flex: 1,
    transition: "all 0.3s ease",
    width: "100%",
  };

  const onRangeChange = (dates: any, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
      onPaginationChange(1, pageSize);
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: null,
          toDate: null,
        })
      );
      onPaginationChange(1, pageSize);
    }
  };

  const handlePreviewOnClick = async (
    path: string,
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    try {
      const { signedUrl } = await documentService.getSignedUrlByFilepath(path);
      setFileUrl(signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err?.message, "error");
    }
  };

  const handleRetry = async (documentUUID: string) => {
    try {
      setRetryingDoc(documentUUID)
      const response = await documentService.retryFailedDocument(documentUUID);
      if (response.data.status === 200) {
        openNotificationWithIcon(
          "Retry Queued",
          response.data.message,
          "success"
        );
        fetchDocuments();
      }else{
        openNotificationWithIcon(
          "",
          response.data.message,
          "warning"
        );
      }
    } catch (error: any) {
      openNotificationWithIcon(
        "Error",
        error.response.data.message || "Failed to retry document",
        "error"
      );
    } finally {
      setRetryingDoc('');
    }
  };

  const handleClickTableRow = (docId: string, checked: boolean) =>
    setSelectRows((prev) => ({
      ...prev,
      rows: checked
        ? [...prev.rows, docId]
        : prev.rows.filter((id) => id !== docId),
    }));

  const handleToggleSelectRows = () =>
    setSelectRows((prev) => ({
      ...prev,
      displayCheckBox: !prev.displayCheckBox,
    }));

  const renderSelectStatus = () => (
    <Select
      defaultValue={"All"}
      style={{ width: "7em", height: 40 }}
      loading={isDocumentsLoading}
      disabled={isDocumentsLoading}
      onChange={handleStatusChange}
    >
      <Option value="">All</Option>
      <Option value="1">Processing</Option>
      <Option value="2">Ready</Option>
      <Option value="3">Failed</Option>
      <Option value="4">Deleted</Option>
    </Select>
  );


  const renderDatePicker = () => (
    <div className="service-datePicker">
      <RangePicker
        showTime={{ 
          use12Hours: true, 
          format: 'h:mm A'  
        }}
        format="YYYY-MM-DD hh:mm A" 
        defaultValue={[
          fromDate ? moment(fromDate) : moment().startOf("month"),
          toDate ? moment(toDate) : moment(),
        ]}
        onChange={onRangeChange}
        className="range-picker"
        allowClear={false}
        disabledDate={(current) =>
          current && current.valueOf() > moment().endOf("day").valueOf()
        }
        style={{ height: "40px" }}
      
      />
    </div>
  );

  const renderRetryActions = (docUUID: string) => {
    if(retryingDoc === docUUID){
      return (
        <div className="retry-container"> 
          <Spin className="link-color"  indicator={<ReloadOutlined spin/>} size="small"/>
          <span className="link-color" >Retrying</span>
          
        </div>
      )
    }
    return(
      <div className="retry-container" onClick={()=>handleRetry(docUUID)}>
        <ReloadOutlined />
        Retry
      </div>
    )
  }

  const getColumns = () => [
    {
      title: "File name",
      key: "name",
      dataIndex: "name",
      className: "filename text-wrapper",
      render: (_: string, record: any) => (
        <span className="row-name">
          {selectRows.displayCheckBox ? (
            <Checkbox
              onChange={(e) =>
                handleClickTableRow(record.documentUUID, e.target.checked)
              }
            />
          ) : null}
          <span onClick={() => navigate(`/document/${record.documentUUID}`)}>
            {record.name}
          </span>
        </span>
      ),
    },
    {
      title: "Transaction Id",
      key: "transactionId",
      dataIndex: "transactionId",
    },
    {
      title: "No. of Pages",
      key: "noOfPages",
      dataIndex: "noOfPages",
      sorter: true,
      sortDirections: ['ascend', 'descend', null] as any['sortDirections'],
      showSorterTooltip: true,
    },
    {
      title: "Total Placeholders",
      key: "noOfPlaceholders",
      dataIndex: "noOfPlaceholders",
      sorter: true,
      sortDirections: ['ascend', 'descend', null] as any['sortDirections'],
      showSorterTooltip: true,
    },
    {
      title: (
        <div className="flex flex-column ">
          <div className="flex jcc">Status</div>
          {renderSelectStatus()}
        </div>
      ),
      key: "Status",
      dataIndex: "Status",
      render: (text: string, record: IUploadedDocumentsDataType) => {
        return (
          <Tag color={statusColors[record.status]} key={record.status}>
            {record.status}
          </Tag>
        );
      },
    },
    {
      title: "Doc Created Time",
      key: "createdAt",
      dataIndex: "createdOn",
      className: "createdOn text-wrapper",
    },
    {
      title: "Doc Updated Time",
      key: "updatedOn",
      dataIndex: "updatedOn",
      className: "updatedOn text-wrapper",
    },
    {
      title: "Processing Time",
      key: "processingTime",
      dataIndex: "processingTime",
      className: "processingTime text-wrapper",
      sorter: true,
      sortDirections: ['ascend', 'descend', null] as any['sortDirections'],
      showSorterTooltip: true,
    },
    {
      title: <div className="flex jce">Action</div>,
      key: "acknowledgement",
      dataIndex: "acknowledgement",
      render: (text: string, record: IUploadedDocumentsDataType) => (
        <div className="action-container">
          
          {record.status === "FAILED" && (renderRetryActions(record.documentUUID))}
          <Button
            shape="circle"
            onClick={() => handleDrawer(record.name, record.documentUUID, record.acknowledgement)}
            icon={<PlusOutlined />}
            size="middle"

          />
          
        </div>
      ),
      className: "action",
    },
  ];

  useEffect(() => {
    fetchDocuments();
  }, [projectId, page, pageSize, searchTerm, fromDate, toDate, status, sortFilter.sort]);

  const data: IUploadedDocumentsDataType[] = documents.map(
    (item: any, index) => ({
      updatedOn: convertToLocalTime(item.updatedAt), 
      name: item.filename,
      key: index,
      documentUUID: item.doc_uuid,
      transactionId: item.transaction_id,
      noOfPages: item.noOfPages || 0,
      noOfPlaceholders: item.noOfPlaceholders || 0,
      status: item.status,
      acknowledgement: item.d_acknowledgement,
      noOfReviews: item.noOfReviews,
      createdOn: convertToLocalTime(item.createdAt),
      processingTime: getTheTimeDifference(item.createdAt, item.updatedAt)
    })
  );

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    onPaginationChange(1, pageSize);
  };

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const handleOnChange = (e: any) => {
    const value = e.target.value.trim();
    setSearchInput(value);
    !value && handleSearch(value);
  };

  // const renderExportAnalysisButton = () => {
  //   if (!selectRows.displayCheckBox) {
  //     return (
  //       <Button className="fill" onClick={handleToggleSelectRows}>
  //         Bulk Export
  //       </Button>
  //     );
  //   }
  //   const handleExportAnalysis = async () => {
  //     if (!selectRows.rows.length) {
  //       return openNotificationWithIcon("", "No documents selected", "info");
  //     }
  //     setSelectRows((prev) => ({ ...prev, exporting: true }));
  //     try {
  //       const res = await documentService.exportAnalysis(selectRows.rows);
  //       const blob = await res.blob();
  //       var file = window.URL.createObjectURL(blob);
  //       window.location.assign(file); // TODO : add filename as doc name
  //       setSelectRows(initialSelectRows);
  //       openNotificationWithIcon(
  //         "",
  //         "Analysis Exported Successfully",
  //         "success"
  //       );
  //     } catch (error) {
  //       openNotificationWithIcon("", "Error exporting analysis", "error");
  //       console.log(error);
  //     } finally {
  //       setSelectRows((prev) => ({ ...prev, exporting: false }));
  //     }
  //   };

  //   return (
  //     <>
  //       <Button
  //         className="fill"
  //         onClick={handleExportAnalysis}
  //         loading={selectRows.exporting}
  //       >
  //         Export Analysis
  //       </Button>
  //       <Button
  //         className="outline"
  //         onClick={() => setSelectRows(initialSelectRows)}
  //       >
  //         Cancel
  //       </Button>
  //     </>
  //   );
  // };

  return (
    <div className="dashboard-table">
      <div className="table-actions">
        <div className="flex gp">
          <Search
            className="record-search m-b"
            placeholder="Search: File Name, Document ID, or Transaction ID"
            enterButton={
              isDocumentsLoading ? (
                <Loading tip="" />
              ) : (
                <Button icon={<SearchIcon />} />
              )
            }
            value={searchInput}
            onChange={(e) => handleOnChange(e)}
            onSearch={(value) => handleSearch(value)}
            disabled={isDocumentsLoading}
            style={{ width: "25em" }}
          />
          {renderDatePicker()}
        </div>

        <div className="header-actions flex gp">
          <AppPagination
            className="tr paginationDiv"
            {...{
              showSizeChanger: true,
              current: page,
              total: totalDocuments,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange, 
            }}
          />
        </div>
      </div>

      <div
        className="dashboard-table__container"
        ref={containerRef}
        style={{ position: "relative", height: "100%" }}
      >
        <div className="dashboard-table__content" style={tableStyle}>
          <Table
            columns={getColumns()}
            pagination={false}
            dataSource={data}
            loading={isDocumentsLoading}
            onChange={(pagination, filters, sorter) => {
              onSortChange(sorter);
          
            }}
            sortDirections={['ascend', 'descend', null]}
            showSorterTooltip={true}
            rowClassName={(record) => 
              record.documentUUID === activeDocData.uuid ? 'highlighted-row' : ''
            }
            locale={{ emptyText: <Empty>No Data Found</Empty> }}
          />
        </div>

        <DrawerComponent
          activeDocData={activeDocData}
          containerRef={containerRef}
          docAckData={docAckData}
          handleDrawer={handleDrawer}
          isDocAckLoading={isDocAckLoading}
          drawer={drawer}
          retryData={retryData}
        />
      </div>
    </div>
  );
};
