import { useCallback, useEffect, useState } from "react";
import { SorterResult } from "antd/es/table/interface";
import {
  STATUS_CODE,
  getOffsetFromPageAndPageSize,
  openNotificationWithIcon,
} from "../utils";
import moment from "moment";
import { usePagination } from "../hooks";
import { documentService } from "../api";
import { IGetDocumentsRequest } from "../interfaces/document";
import { useSelector } from "react-redux";
import { RootState } from "../store";

interface ColumnSort {
  field: string | null;
  sortOrder: 'ascend' | 'descend' | null;
}



export const useFetchDocuments = () => {
  const { page, onPaginationChange, pageSize, pageSizeOptions } =
    usePagination();
    const [sort, setSort] = useState<ColumnSort>({
      field: null,
      sortOrder: null
    });  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(true);

  const defaultOverviewFromDate = moment("2023-08-01 00:00:00");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDocumentUUID, setSearchDocumentUUID] = useState("");
  const [status, setStatus] = useState("");
  const [keywords, setKeywords] = useState([] as Record<string, string>[]);
  const [ isDocAckLoading, setIsDocAckLoading ] = useState<boolean>(false)
  const [ docAckData, setDocAckData ] = useState<any[]>([]);
  const [ retryData, setRetryData ] = <any> useState()

  const { fromDate, toDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );

  const onSortChange = (sorter: SorterResult<any> | SorterResult<any>[]) => {
    
    if (!Array.isArray(sorter)) {
      const newSort: ColumnSort = {
        field: sorter.field?.toString() || null,
        sortOrder: sorter.order || null,
      };
  
      if (newSort.field !== sort.field || newSort.sortOrder !== sort.sortOrder) {
        setSort(newSort);
      } 
    }
  };
  

  const getDocumentsFilterObject = useCallback(
    (projectId?: number) => {
      const fromDateWithTime = moment(fromDate)
      .format('YYYY-MM-DD HH:mm:ss');
    
    const toDateWithTime = moment(toDate)
      .format('YYYY-MM-DD HH:mm:ss');
      
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let obj: IGetDocumentsRequest = {
        limit: pageSize,
        offset: getOffsetFromPageAndPageSize(page, pageSize),
        searchTerm: searchTerm,
        searchDocumentUUID: searchDocumentUUID,
        status: status,
        projectId: projectId,
        fromDate:fromDateWithTime?.toString(),
        toDate: toDateWithTime?.toString(),
        timezone,
      }
      if (sort.field && sort.sortOrder) {
        obj.sortField = sort.field;
        obj.sortType = sort.sortOrder === 'ascend' ? 'asc' : 'desc';
      }
      return { ...obj };
    },
    
    [
      page,
      pageSize,
      sort,
      fromDate,
      toDate,
      searchTerm,
      searchDocumentUUID,
      status,
    ]
  );

  const fetchDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      const {data, pagination} = await documentService.getDocuments(
        getDocumentsFilterObject()
      );
      setDocuments(data);

      setTotal(pagination.total ? pagination.total : 0);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [getDocumentsFilterObject]);

  // useEffect(() => {
  //   fetchDocuments();
  // }, []);

  const fetchDocAckData = async (uuid: string)=> {
    setDocAckData([])
    setIsDocAckLoading(true);
    try{
      const { data } = await documentService.getAcknowledgementInfo(uuid);
      setDocAckData(data)
    }catch (err: any){
      openNotificationWithIcon("", err.response.data.message, "error");
    }finally {
       setIsDocAckLoading(false)
    }
  };

  const fetchRetryData = async (uuid: string) => {
    setIsDocAckLoading(true);
    try{
      const { data } = await documentService.fetchRetryAttempts(uuid);
      setRetryData(data)
    }catch (err: any){
      openNotificationWithIcon("", err.response.data.message, "error");
    }finally {
       setIsDocAckLoading(false)
    }
  }



  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    getDocumentsFilterObject,
    fetchDocuments,
    isDocumentsLoading: isLoading,
    setIsDocumentsLoading: setIsLoading,
    documents,
    totalDocuments: total,
    setDocuments,
    setTotalDocuments: setTotal,
    isAuthorized,
    fromDate,
    toDate,
    searchTerm,
    setSearchTerm,
    searchDocumentUUID,
    setSearchDocumentUUID,
    status,
    setStatus,
    field: keywords,
    defaultOverviewFromDate,
    isDocAckLoading, 
    fetchDocAckData,
    docAckData,
    fetchRetryData,
    retryData,
    onSortChange
  };
};
